<template>
    <a-card>
        <a-page-header title='广告主销售目标城市详情'>
            <a-button @click="$router.back()">返回</a-button>
        </a-page-header>
        <div style="margin: 15px 30px;">
            广告主： {{ advertiserName }}
        </div>
        <div class="bottom">
            <div class="tree-box">
                <a-tree v-if="showTree" checkable showLine v-model="checkedKeys" :expanded-keys="expandedKeys"
                    :tree-data="treeData" @expand="onExpand" @check="onCheck">
                </a-tree>
                <a-icon type="loading" v-else />
            </div>
            <a-button type="primary" style="width: 150px;" :loading="btnLoading" @click="handleSave">保存</a-button>
        </div>
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            showTree: false,
            expandedKeys: ['0'],
            checkedKeys: [],
            checkedData: [],
            treeData: [
                {
                    title: '全国',
                    key: '0',
                    parentId: '-1',
                    level: 0,
                    selectable: false,
                    children: []
                }
            ],
            btnLoading: false,
            advertiserName: null,
        };
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            const { code, data, message } = await this.$api.core.pphtManage.getAccountDetail(this.$route.query.id)
            if (code === 200) {
                // 已选数据回显
                this.checkedData = data.targetCityArr?.map(val => {
                    this.checkedKeys.push(val.id)
                    return this.treeItemFormat(val)
                })
                this.advertiserName = data.advertiserName
                this.getAreaData()
            } else {
                this.$message.info(`${message}`)
            }
        },
        async getAreaData() {
            const treeList = []
            // 省
            const level_1 = await this.$api.core.pphtManage.getLevelArea(1)
            level_1.forEach(value => {
                treeList.push(this.treeItemFormat(value))
            })
            // 市
            const level_2 = await this.$api.core.pphtManage.getLevelArea(2)
            level_2.forEach(value => {
                treeList.forEach(val => {
                    // 直辖市
                    if (['110000', '120000', '310000', '500000'].some(code => code === value.parent_id)) return
                    if (val.key === value.parent_id) val.children.push(this.treeItemFormat(value))
                })
            })
            this.treeData[0].children = treeList
            this.$nextTick(() => {
                this.showTree = true
            })
        },
        treeItemFormat(value) {
            return {
                key: value.id,
                title: value.name,
                parentId: value.parent_id || value.parentId,
                level: value.level,
                selectable: false,
                children: []
            }
        },
        async handleSave() {
            const checkedList = this.checkedData?.map(val => {
                return {
                    id: val.key,
                    level: val.level,
                    name: val.title,
                    parentId: val.parentId
                }
            }) || []
            let targetCityArr = []
            // 如果勾选全国只传全国，如果省份下的市全选只传省份
            if (checkedList.some(val => val.id === '0')) {
                const data = checkedList.find(val => val.id === '0')
                delete data.selectable
                targetCityArr.push(data)
            } else {
                const parentList = checkedList.filter(val => val.level === 1)
                checkedList.forEach(val => {
                    const hasParent = parentList.some(value => value.id === val.parentId)
                    if (!hasParent) {
                        delete val.selectable
                        parentList.push(val)
                    }
                })
                targetCityArr = [...new Set(parentList)]
            }
            const params = {
                advertiserId: this.$route.query.id,
                targetCityArr,
            }
            this.btnLoading = true
            const { code, message } = await this.$api.core.pphtManage.updateBindCity(params).finally(() => {
                this.btnLoading = false
            })
            if (code === 200) {
                this.$message.success('更新成功')
                Object.assign(this.$data, this.$options.data())
                this.init()
            } else {
                this.$message.info(`${message}`)
            }
        },
        onExpand(expandedKeys) {
            this.expandedKeys = expandedKeys;
        },
        onCheck(checkedKeys, val) {
            this.checkedKeys = checkedKeys;
            this.checkedData = val.checkedNodes.map(val => val.data.props.dataRef)
        },
    },
}
</script>

<style scoped lang="less">
.bottom {
    display: flex;
    margin-top: 20px;
    padding-left: 25px;

    .tree-box {
        margin-right: 150px;
        width: 350px;
        height: 450px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: #00152984;

            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #0015294c;
        }

        &::-webkit-scrollbar-corner {
            background: #fff;
        }
    }
}
</style>
        